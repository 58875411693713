import React from "react"; 
 
import Banner from "components/Landpage/Banner";
import HowWorks from 'components/Landpage/HowWorks'
import MoreInfos from 'components/Landpage/MoreInfos'
import CommomCases from 'components/Landpage/CommomCases'
import KnowMore from 'components/Landpage/KnowMore'
import LittleMore from 'components/Landpage/LittleMore' 

import ContainerLandpage from "containers/Landpage";
import CarouselImages from "components/Landpage/CarouselImages";
import BannerMiddle from "components/Landpage/BannerMIddle";
import BannerBottom from "components/Landpage/BannerBottom";

export default function Landpage(){ 
 
    return ( 
        <ContainerLandpage> 

                <Banner /> 
                <HowWorks /> 
                <BannerMiddle /> 
                <KnowMore /> 
                <BannerBottom /> 
                <CommomCases /> 


                {/* <CarouselImages />  */}
                {/* <LittleMore />   */}
 
        </ContainerLandpage>
    );
}