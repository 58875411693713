import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
 
import CardSelect from 'components/Landpage/Card/Select'

import { 
    BannerImage,
    BannerOut,
    BannerContent
} from "./styled"; 


import { ButtonContent, ContentCenter, Subtitle, Title } from "ui/styled";
import Button from "components/Form/Button";
import { goContact } from "utils";


export default function Banner(){

    return ( 
        <> 
            <BannerImage>
                <BannerOut />
                <BannerContent>
                    <Container>
                        <Row>
                            <Col md={{ size: 6 }}> 
                                <ContentCenter>
                                    <Subtitle>HS Comércio Instaladora e Serviços LTDA.</Subtitle>
                                    <Title>Engenharia e Instalações Eletricas em Brasília</Title>

                                    <ButtonContent>
                                        <Button outline onClick={() => goContact()}>Entrar em contato</Button>
                                    </ButtonContent>

                                </ContentCenter>
                            </Col>
                        </Row>
                    </Container>
                </BannerContent>
            </BannerImage>
        </>
    );
}