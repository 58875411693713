import styled from 'styled-components' 
 
 
export const KnowContent = styled.div.attrs({ 
})`          
    padding: 25px 0;
    border-top: 10px solid ${ props => props.theme.palette.colors.lightshadow };
`;

export const MoreTitle = styled.div.attrs({ 
})`          
    font-size: 32px;
    font-weight: bold;
    color: ${ props => props.theme.palette.secondary.main };
    margin-bottom: 6px;
`;
  
export const MoreText = styled.div.attrs({ 
})` 
    color: ${ props => props.theme.palette.colors.grey };
    font-size: 18px; 
`;
  

export const ContainerLogosMarquee = styled.div.attrs({ 
})` 
`;

export const ContainerLogos = styled.div.attrs({ 
})` 
    display: flex;
    max-width: 100%; 
`;
export const LogoItem = styled.div.attrs({ 
})` 
    padding: 20px;
    background: transparent url(${ p => p.image }) no-repeat center center / cover;
    flex:1;
    aspect-ratio: 4 / 3; 
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.1);
    }
`;
  