import React, { useEffect, useRef } from "react"; 

import { Row, Col, Container } from 'reactstrap'; 

import Item from './Item'

import {
    MoreTitle,
    MoreText,
    KnowContent,
    ContainerLogos,
    LogoItem,
    ContainerLogosMarquee,
    LogoItemContent
} from './styled' 

export default function KnowMore(){ 

    const doctors = [
        {
            image: '/images/clients/1.png',
        },
        {
            image: '/images/clients/2.png',
        },
        {
            image: '/images/clients/3.png',
        },
        {
            image: '/images/clients/5.png',
        },
        {
            image: '/images/clients/4.png',
        },
    ]
 

    const scrollInterval = 3000
    const containerRef = useRef(null);

    useEffect(() => {
      if (containerRef.current) {
        const container = containerRef.current;
        let scrollLeft = 0;
        const scrollSize = ( container.scrollWidth / doctors?.length)
        const scrollWidth = container.scrollWidth - scrollSize;
  
        const interval = setInterval(() => {
          if (scrollLeft >= scrollWidth) {
            scrollLeft = 0;
          } else {
            scrollLeft += scrollSize; // Adjust speed as needed
          }
          container.scrollTo({
            left: scrollLeft,
            behavior: 'smooth',
          });
        }, scrollInterval);
  
        return () => clearInterval(interval);
      }
    }, [scrollInterval]);


    return ( 
        <> 
            <KnowContent id="clients">
                <Container>
                    <Row>
                        <Col>
                            <MoreTitle>Clientes</MoreTitle>
                            <MoreText>Nossa carteira de clientes abrange diversas indústrias e setores, incluindo grandes empresas, pequenas e médias empresas, e projetos residenciais. Orgulhamo-nos de manter relacionamentos duradouros e de confiança com nossos clientes, muitos dos quais nos consideram uma referência em serviços de engenharia civil e elétrica. A satisfação e os feedbacks positivos de nossos clientes são a prova de nosso compromisso com a excelência.</MoreText>
                        </Col>
                    </Row> 
                    <Row>
                        <Col>
                            <ContainerLogosMarquee ref={containerRef}>
                                <ContainerLogos>
                                    {
                                        doctors?.map((item, key) => 
                                            <LogoItemContent key={key} >
                                                <LogoItem  image={item.image} />
                                            </LogoItemContent>
                                        ) 
                                    }
                                </ContainerLogos>
                            </ContainerLogosMarquee>
                        </Col>
                        {/* {
                            doctors.map((item, key) => 
                                <Col key={key} md={{ size:6 }}> 
                                    <Item {...item} />
                                </Col>
                            )
                        } */}
                    </Row>  
                </Container> 
            </KnowContent>
        </>
    );
}