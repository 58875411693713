import styled from 'styled-components' 
 
 
export const KnowContent = styled.div.attrs({ 
})`          
    padding: 80px 0 70px;
    border-top: 10px solid ${ props => props.theme.palette.colors.lightshadow };
`;

export const MoreTitle = styled.div.attrs({ 
})`          
    font-size: 32px;
    font-weight: bold;
    color: ${ props => props.theme.palette.secondary.main };
    margin-bottom: 6px;
`;
  
export const MoreText = styled.div.attrs({ 
})` 
    color: ${ props => props.theme.palette.colors.grey };
    font-size: 18px; 
`;
  

export const ContainerLogosMarquee = styled.div.attrs({ 
})` 
 
    overflow: auto;
    scroll-snap-type: x mandatory;


    &::-webkit-scrollbar {
        display: none;
    }
`;

export const ContainerLogos = styled.div.attrs({ 
})` 
    display: flex;
    padding: 120px 0px 40px 0px;
    gap: 0 0px;
    min-width: fit-content;
`;
export const LogoItemContent = styled.div.attrs({ 
})` 
    min-width: 100vw;
    display: flex;
    justify-content: center;
`;
  

export const LogoItem = styled.div.attrs({ 
    className:"container"
})` 
    padding: 20px;
    background: transparent url(${ p => p.image }) no-repeat center center / contain;
    flex:1;
    aspect-ratio: 4 / 1;
    // background: red;
    cursor: pointer;
    transition: all .3s ease;

    height: 80px;
    &:hover{
        transform: scale(1.1);
    }
    scroll-snap-align: start; 
`;
  