import React, { useState } from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
  
import {
    CommomContainer,
    CommomDecoration,
    CommomTitle,
    CommomList,
    CommomListItem,
    CommomAnimation,
    CommomText,
    CommomAnimationContainer,
    CommomListItemText
} from './styled'

export default function CommomCases(){ 

    const [opened, setOpened] = useState([])

    const commomLeft = [
        { title: 'Sustentabilidade Ambiental', onClick: () => toggle(0) },
        { title: 'Práticas Sustentáveis', text:"Adotamos práticas de construção e instalação que minimizam o impacto ambiental, utilizando materiais eco-friendly e técnicas que promovem a eficiência energética.", ref:0},
        { title: 'Gestão de Resíduos', text:"Implementamos um rigoroso sistema de gestão de resíduos, garantindo a reciclagem e o descarte adequado de materiais, contribuindo para a preservação do meio ambiente.", ref:0},


        { title: 'Responsabilidade Social', onClick: () => toggle(1) },
        { title: 'Projetos Comunitários', text:"Envolvemo-nos ativamente em projetos que beneficiam a comunidade local, desde a construção de infraestruturas até programas de capacitação profissional.", ref:1},
        { title: 'Inclusão Social', text:"Promovemos a inclusão social ao oferecer oportunidades de emprego e treinamento para membros da comunidade, contribuindo para o desenvolvimento econômico e social da região.", ref:1},

        { title: 'Compromisso Ético', onClick: () => toggle(2) },
        { title: 'Transparência e Integridade', text:"Nossa conduta é guiada pela ética e transparência em todas as nossas ações, mantendo um relacionamento de confiança com clientes, colaboradores e parceiros.", ref:2},
        { title: 'Segurança no Trabalho', text:"Priorizamos a segurança e o bem-estar de nossos colaboradores, implementando rigorosos protocolos de segurança e promovendo um ambiente de trabalho saudável.", ref:2},
        
        { text: 'Com esses valores e práticas, buscamos não apenas o sucesso empresarial, mas também um impacto positivo e duradouro na sociedade e no meio ambiente.'},
    ]

    const commomRight = [ 
        
    ]

    const isOpened = item => {
        return opened?.includes(item)
    }

    const toggle = item => {
        setOpened(
            isOpened(item) ? opened?.filter(f => f !== item ) : [
                ...opened, item
            ]
        )
    }

    return ( 
        <> 
            <CommomContainer id="social-responsabily"> 
                <CommomDecoration />
                <Container>
                    <Row>
                        <Col>
                            <CommomTitle>Responsabilidade Social e Ambiental</CommomTitle>
                            <CommomText>Na HS. acreditamos que nosso papel vai além da engenharia. Estamos comprometidos com práticas que promovem a sustentabilidade e o bem-estar da comunidade.</CommomText>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{size:7}}>
                            <Row>
                                <Col md={{size:12}}>
                                    <CommomList>
                                        {
                                            commomLeft.map((item, key) => 
                                                <CommomListItem key={key} title={!item.text} notitle={!item.title} open={isOpened(item.ref)} onClick={item.onClick}>
                                                    { item.title }
                                                    <CommomListItemText>
                                                        { item.text }
                                                    </CommomListItemText>
                                                </CommomListItem>
                                            )
                                        }
                                    </CommomList>
                                </Col>
                                {/* <Col md={{size:6}}>
                                    <CommomList>
                                        {
                                            commomRight.map((item, key) => 
                                                <CommomListItem key={key}>
                                                    { item.title }
                                                </CommomListItem>
                                            )
                                        }
                                    </CommomList>
                                </Col> */}
                            </Row>
                        </Col>
                        <Col md={{size:5}}>
                            <CommomAnimationContainer>
                                <CommomAnimation animationData={require('assets/lotties/sustainable.json')} />
                            </CommomAnimationContainer>
                            {/* <img src={'/images/taon-illustration1.svg'} className="commom-cases-image"/> */}
                        </Col>
                    </Row>
                </Container>
            </CommomContainer>
        </>
    );
}