import React, { useState } from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
 
import ToggleTab from 'components/Landpage/Toggle/Tab'
import HowWorksItem from './Item'

import {
    HowWorksContainer,
    HowWorksDecoration,
    HowWorksTitleContent,
    HowWorksTitle,
    HowWorksText
} from './styled'

export default function HowWorks(){

    const [activeTab, setActive] = useState(1)

    const tabOptions = [
        { title:'Sobre' },
        { title:'Beneficios' }
    ]

    const optionsReady = [
        {
            number: '',
            subtitle: 'Qualidade Superior',
            text: 'Utilizamos materiais de alta qualidade e técnicas de construção avançadas para garantir resultados duradouros e eficientes.'
        },
        {
            number: '',
            subtitle: 'Equipe Experiente',
            text: 'Contamos com profissionais experientes e capacitados, comprometidos com a excelência em cada etapa do projeto.'
        },
        {
            number: '',
            subtitle: 'Pontualidade',
            text: 'Respeitamos os prazos acordados, garantindo a entrega dos projetos dentro do cronograma estabelecido.'
        },
        {
            number: '',
            subtitle: 'Soluções Personalizadas:',
            text: 'Oferecemos soluções adaptadas às necessidades específicas de cada cliente, garantindo satisfação total.'
        }
    ]

    const optionsSchedule = [
        {
            number: '',
            subtitle: 'Referência',
            text: 'A HS. é uma empresa de destaque no setor de engenharia civil e elétrica na região de Brasília atendendo todo o Brasil. Com uma trajetória sólida e um compromisso inabalável com a qualidade, fornecemos serviços de construção, instalação elétrica, criação de projetos e orçamentos personalizados.'
        },
        // {
        //     number: '2',
        //     subtitle: 'Qualidade',
        //     text: ''
        // },
        {
            number: '',
            subtitle: 'Atendimento ao Cliente',
            text: 'Nossa equipe de profissionais altamente qualificados e nossa dedicação ao atendimento do cliente vai além da execução do projeto, oferecendo suporte contínuo e atendimento de excelência.'
        }
    ]

    return ( 
        <> 
            <HowWorksContainer id="why">
                <HowWorksDecoration />
                <Container>
                    <Row>
                        <Col>
                            <HowWorksTitleContent>
                                <HowWorksTitle>Porque nos contratar?</HowWorksTitle>
                                <HowWorksText>
                                    Escolher a HS Comércio Instaladora e Serviços Ltda. significa optar por excelência, confiança e inovação. Com anos de experiência no mercado, entendemos as demandas específicas de cada projeto e trabalhamos incansavelmente para superá-las. Nossa abordagem personalizada e nossa capacidade de entregar resultados excepcionais dentro dos prazos e orçamentos estabelecidos são apenas algumas das razões pelas quais nossos clientes nos escolhem repetidamente. Confie em nós para transformar suas visões em realidade.
                                </HowWorksText>
                            </HowWorksTitleContent>
                            <ToggleTab options={tabOptions} active={activeTab} onClick={setActive} /> 
                        </Col>
                    </Row>
                    <Row>
                        {
                            activeTab === 0 ? 
                                optionsSchedule.map((item, key) => 
                                    <Col key={key} md={{ size:6 }}>
                                        <HowWorksItem number={ item.number } subtitle={ item.subtitle } text={ item.text } variant={key} />
                                    </Col>
                                )
                            : null
                        } 
                        {
                            activeTab === 1 ? 
                                optionsReady.map((item, key) => 
                                    <Col key={key} md={{ size:3 }}>
                                        <HowWorksItem number={ item.number } subtitle={ item.subtitle } text={ item.text } variant={key} />
                                    </Col>
                                )
                            : null
                        } 
                    </Row>
                </Container>
            </HowWorksContainer>
        </>
    );
}