import styled from 'styled-components' 

import Lottie from 'react-lottie';

export const CommomContainer = styled.div.attrs({ 
})`          
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
    border-top: 10px solid ${ props => props.theme.palette.colors.lightshadow };
`;

export const CommomDecoration = styled.img.attrs({ 
    src:'/icons/circle-2.svg'
})`          
    position: absolute;
    width: 493px;
    right: -320px;
    bottom: 20px;
`;

export const CommomTitle = styled.h1.attrs({  
})`          
    font-size: 32px;
    font-weight: bold;
    color: ${ props => props.theme.palette.secondary.main };
    margin: 0;
`;  

export const CommomText = styled.div.attrs({ 
})`          
    margin-top: 12px; 
    color: ${ props => props.theme.palette.colors.grey };
    font-size: 20px; 
`; 

export const CommomList = styled.ul.attrs({  
})`          
    list-style: none;
    padding: 0;
    margin-top: 70px;
    margin-left: 20px;
`;  

export const CommomListItem = styled.li.attrs({  
    className:"list-item"
})`          
    font-size: 20px;
    margin-bottom: 20px;
    color: ${ props => props.theme.palette.colors.grey };
    ${
        p => !p.title && !p.notitle ? `
            display: none;
            &:before{
                content: "\\2022"; 
                color: ${ p.theme.palette.primary.main }; 
                font-weight: bold; 
                display: inline-block; 
                width: 1em; 
                margin-left: -1em;
            }
            
        ` : `
            font-weight: bold; 
        `
    }
    ${
        p => p.onClick ? ` 
            cursor:pointer;
        ` : `
        `
    }
    ${
        p => !p.open ? ` 
        ` : `
            display: block;
        `
    }



`;  

export const CommomListItemText = styled.div.attrs({  
})`          
    font-size: 16px;
    margin-top: 6px;
`;  


export const CommomAnimationContainer = styled.div.attrs({  
})`          
    max-width: 320px;
    margin: 40px 0 0 auto;
`;  

export const CommomAnimation = styled(Lottie).attrs( props => ({  
    options:{
        loop: true,
        autoplay: true, 
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        },
        animationData: props.animationData
    },
    // height: 500,
    // width: 500
}))`            
    width: 100%;
`;  