import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
import { useHistory } from 'react-router-dom';

import {
    FooterContainer,
    FooterLogo,
    FooterTitle,
    FooterText,
    FooterLink,
    FooterLinkImage,
    FooterSection,
    FooterPhoneContent,
    FooterPhoneIcon,
    FooterCopyright,
    FooterIcon,
    FooterMailIcon,
    FooterPinIcon
} from './styled' 

export default function Footer(){ 
 
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const text = 'Olá, gostaria de conversar sobre um projeto de engenharia civil ou instalação elétrica'

    return ( 
        <> 
            <FooterContainer>
                <Container>
                    <Row>
                        <Col md={{size:5}}>
                            {/* <FooterLogo /> */}
                            <FooterTitle>HS Comércio Intaladora e Serviços LTDA.</FooterTitle>
                            <a href={`https://www.google.com/maps/dir//hs+comercio+instaladora+e+servicos/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x935985436342c559:0x9503bf01598eaa74?sa=X&ved=1t:3061&ictx=111`} target="new">
                                <FooterPhoneContent>
                                    <FooterPinIcon />
                                    <FooterLink>Quadra 6 Lote 30, Rio das Pedras, Valparaíso / GO</FooterLink>
                                </FooterPhoneContent>
                            </a>
                            <a href={`https://api.whatsapp.com/send/?phone=5561998915961&text=${text}`} target="new">
                                <FooterPhoneContent>
                                    <FooterPhoneIcon />
                                    <FooterLink>+55 (61) 99891-5961</FooterLink>
                                </FooterPhoneContent>
                            </a>
                            <a href={`mailto:hs.inserv@gmail.com?body=${text}`} target="new">
                                <FooterPhoneContent>
                                    <FooterMailIcon />
                                    <FooterLink>hs.inserv@gmail.com</FooterLink>
                                </FooterPhoneContent>
                            </a>
                        </Col>
                        <Col md={{size:7}}>
                            <Row>
                                <Col md={{size:6}}>
                                    {/* <FooterSection>
                                        <FooterTitle>&nbsp;</FooterTitle>
                                        <FooterLink onClick={() => navigate('')}>Inicio</FooterLink>
                                        <FooterLink onClick={() => navigate('about')}>Sobre</FooterLink>
                                    </FooterSection> */}
                                </Col>
                                <Col md={{size:6}}> 
                                    <FooterSection>
                                        <FooterTitle>Nos siga nas redes</FooterTitle>
                                        {/* <FooterLink>
                                            <FooterLinkImage src={'/icons/face.svg'} />
                                            Facebook
                                        </FooterLink>  */}

                                        <a href={"https://instagram.com/hsinstaladora_e_servicos"} target="new">
                                            <FooterLink>
                                                <FooterLinkImage src={'/icons/insta.svg'} />
                                                Instagram
                                            </FooterLink>
                                        </a>
                                    </FooterSection>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col > 
                            <FooterCopyright>Powered by Unicorn Tech House</FooterCopyright> 
                            <a href={"https://unicorntechhouse.com"} target="new">
                                <FooterIcon />
                            </a>
                        </Col> 
                    </Row>
                </Container>
            </FooterContainer>
        </>
    );
}